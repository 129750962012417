import {Emoji1Icon, Emoji2Icon, Emoji3Icon, Emoji4Icon, Emoji5Icon} from "../../assets/svg/icons";
import React from "react";


const FeedbackForm = ({title}) => {

    return (
        <div className={'feedback-wrapper'}>

            <h1>{title}</h1>

            <div className={'feedback-wrapper-emoji'}>

                <div className={'feedback-wrapper-emoji-item'}>
                    <Emoji1Icon/>
                </div>
                <div className={'feedback-wrapper-emoji-item'}>
                    <Emoji2Icon/>
                </div>
                <div className={'feedback-wrapper-emoji-item'}>
                    <Emoji3Icon/>
                </div>
                <div className={'feedback-wrapper-emoji-item'}>
                    <Emoji4Icon/>
                </div>
                <div className={'feedback-wrapper-emoji-item'}>
                    <Emoji5Icon/>
                </div>

            </div>

            <textarea></textarea>

            <button>Ответить</button>

        </div>
    )
}

export default FeedbackForm
