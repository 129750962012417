import {Sheet} from "react-modal-sheet";
import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {WheelStorageRedBottomArrowIcon, WheelStorageYellowBottomArrowIcon} from "../../../../assets/svg/icons";


const WheelStorageInfo = ({isOpen, setOpen, isRed}) => {
    const navigate = useNavigate();
    const ref = useRef();

    const [height, setHeight] = useState(250);


    const snapTo = (i) => ref.current?.snapTo(i);

    return (
        <Sheet
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            initialSnap={0}
            snapPoints={[height, 200, 400, 0]}
        >
            <Sheet.Container>

                <Sheet.Header />
                <Sheet.Content>

                    <div className={'weel-storage-bottomshet-wrapper'}>

                        <div className={'weel-storage-wrapper'}>
                            <div className={`weel-storage-message ${isRed ? 'red' : 'yellow'}`}>
                                {isRed ?
                                    'Срок хранения партии истёк. Просим вас забрать колёса. Не забудьте взять договор и акт приёма-передачи, удостоверяющий факт сдачи шин на хранение.'
                                    :
                                    'Вы можете забрать  колёса в любой момент, не дожидаясь окончания срока хранения. Не забудьте взять договор и акт приёма-передачи, удостоверяющий факт сдачи шин на хранение.'
                                }

                                {isRed ?
                                    <WheelStorageRedBottomArrowIcon className={'weel-storage-bottom-icon'}/>
                                    :
                                    <WheelStorageYellowBottomArrowIcon className={'weel-storage-bottom-icon'}/>
                                }

                            </div>
                            <div className={'bottom-btns-wrapper'} style={{padding: 0, paddingBottom: 0}}>
                                <button
                                    style={{flex: 1}}
                                    onClick={() => {
                                        setOpen(false)
                                        setTimeout(()=>{
                                            setHeight(250)
                                        }, 1000)
                                    }}
                                    className={'simple-btn'}
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>

                    </div>

                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop/>
</Sheet>
)
}

export default WheelStorageInfo
