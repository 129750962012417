import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";

import Loader from "../../../components/Loader";
import useTranslation from "../../../hooks/useTranslation";
import Header from "../../../components/Header";
import {useSingleData} from "../../../context/SingleContext";
import {LogoIcon} from "../../../assets/svg/icons";
// import SliderComponent from "../../components/SliderComponent";

function MyPurchasesSingle() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();
    const { selectedSingleData, setSelectedSingleData } = useSingleData(); // Получаем выбранный заказ из контекста

    const [loader, setLoader] = useState(true);

    useEffect(()=>{
        if (selectedSingleData) {
            setLoader(false)
        }
        console.log(selectedSingleData, 'selectedOrder')
    }, [selectedSingleData])



    const handleNavigation = () => {
        let  address = encodeURIComponent(selectedSingleData?.address) ;
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        const url = isIOS
            ? `maps://maps.apple.com/?daddr=${address}`   // Открывает Apple Maps на iOS
            : `https://www.google.com/maps/search/?api=1&query=${address}`;  // Открывает Google Maps на Android и других устройствах

        window.open(url, '_blank');
    };


    if (loader) {
        return (
            <Loader/>
        )
    }

    return (

        <div className="wrapper wrapper-flex">

            <Header/>

            <Link to={'/'} className="top-block-logo">
                <LogoIcon/>
            </Link>

            <div className={'list-wrapper'} style={{flex: 1, marginTop: 70, overflow: 'scroll'}}>

                <div className={'list-item'} style={{background: 'unset', padding: 0}}>
                    <div className={'list-item-center'} style={{flexDirection: "column", alignItems: 'flex-start'}}>
                        <div style={{display: "flex"}}>
                            <span style={{
                                fontSize: 20,
                                fontWeight: 700
                            }}>
                                Заказ № {selectedSingleData?.orderNumber}
                            </span>
                            {selectedSingleData?.cancellationProcess == 1 &&
                                <p className={'cancelation-yellow-block'}>отменяем</p>
                            }

                        </div>

                        <span style={{paddingLeft: 0}}>от {selectedSingleData?.orderDate} г.</span>
                    </div>

                </div>

                {selectedSingleData?.products && selectedSingleData?.products.map((item, index) => {
                    return (
                        <div key={index} className={'order-item'}>
                            <div className={'order-item-left'}>
                                <img src={item?.image}/>
                            </div>
                            <div className={'order-item-right'}>
                                <p className={'order-item-title'}>
                                    {item?.title}
                                </p>
                                <p className={'order-item-price'}>{item?.price / item?.quantity} ₽/шт.</p>
                                <div className={'order-item-bottom'}>
                                    <span>{item?.quantity} шт.</span>
                                    <span style={{fontSize: 16, fontWeight: '700'}}>{item?.price} ₽</span>
                                </div>
                            </div>

                        </div>
                    )
                })}


            </div>


             <div className={'method-of-obtaining-wrapper'} style={{marginBottom:0}}>
                 <p className={'method-of-obtaining-text1'}>Способ получения:</p>
                 <p className={'method-of-obtaining-text2'}>{selectedSingleData?.deliveryType},  {selectedSingleData?.address}</p>
                 {selectedSingleData?.deliveryType == 'Самовывоз' &&
                     <p
                         onClick={handleNavigation}
                         className={'build-a-route'} style={{
                         fontSize: 13,
                         color: "#4E2B96",
                         display: "inline-flex",
                         justifyContent: 'center',
                         alignItems: 'center'
                     }}>Построить маршрут</p>
                 }
             </div>

            <div className={'bottom-btns-wrapper'} style={{padding: 0, paddingBottom: 20}}>
                <button
                    style={{flex: 1, marginRight: 0}}
                    onClick={() => {
                        navigate(-1);
                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>
            </div>


        </div>
    );
}

export default MyPurchasesSingle;
