import React, {useEffect, useState} from "react";
// import Loader from "react-js-loader";
// import {LoaderLogoIcon} from "../icons/LoaderLogoIcon";
// import SliderSubscribeModal from "../SliderSubscribeModal";
import LoaderComponent from "../Loader";
import {LogoIcon} from "../../assets/svg/icons";

const SliderComponent = ({onClose}) => {

    const [activeSlide, setActiveSlide] = useState(0)
    const [loading, setLoading] = useState(false);

    const [slides, setSlides] = useState([
        {
            img: require('../../assets/img/splash1.png')
        },

        {
            img: require('../../assets/img/splash1.png')
        },

        {
            img: require('../../assets/img/splash1.png')
        },

    ]);

    useEffect(() => {
        setTimeout(()=>{
            onClose();
        }, 2000)
    }, [activeSlide]);


    const handlePrev = () => {
        setActiveSlide(prevState => prevState == 0 ? 0 : prevState - 1)
    }

    const handleNext = () => {
        setActiveSlide(prevState => prevState + 1)
    }

    return (
        <div className={'slider-wrapper'}>

            {loading &&
                <LoaderComponent/>
            }

            <>

                <div className={'slider-wrapper-container'}>

                    <div className={'slider-wrapper-container'}>
                        <div className={'slider-wrapper-container-bg'}></div>

                        <div className={'slider-wrapper-container-header'}>

                            <LogoIcon width={60} height={60}/>

                            <p>
                                Добро пожаловать <br/> в Колесо.ру!
                            </p>
                        </div>

                        {slides.map((item, index) => {
                            return (
                                <div key={index}
                                     className={`slider-pagination-item ${activeSlide == index && 'active'}`}>
                                    <img src={item?.img} alt=""/>
                                </div>
                            )
                        })}

                    </div>


                    {/*<div className={'next-slide-wrapper'}>*/}

                    {/*    <button*/}
                    {/*        style={{marginRight: 10, fontStyle:'italic'}}*/}
                    {/*        className='car_dealership_modal_no_button'*/}
                    {/*        onClick={() => {*/}
                    {/*            handlePrev()*/}

                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <div className="car_dealership_modal_no_button_child no-gas-by-gas">*/}
                    {/*            <p className='car_dealership_modal_no_button_text'>PREV</p>*/}
                    {/*        </div>*/}
                    {/*    </button>*/}

                    {/*    <button*/}
                    {/*        style={{fontStyle:'italic'}}*/}
                    {/*        onClick={() => {*/}
                    {/*            handleNext()*/}
                    {/*        }}*/}
                    {/*        className='car_dealership_modal_apply_button'*/}
                    {/*    >*/}

                    {/*        <div*/}
                    {/*            onClick={() => {*/}

                    {/*            }}*/}
                    {/*            className='car_dealership_modal_apply_button_child'>*/}
                    {/*            <p className='car_dealership_modal_apply_button_text'>NEXT</p>*/}
                    {/*        </div>*/}

                    {/*    </button>*/}
                    {/*</div>*/}

                </div>
            </>


        </div>
    );
};
export default SliderComponent;
