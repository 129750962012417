import apiClient from '../apiClient'; // Импорт вашего axios клиента

// Функция для получения списка марок автомобилей
export const getAutoMarks = async () => {
    try {
        const response = await apiClient.get('/catalog/get-auto-mark');
        return response.data;
    } catch (error) {
        console.error('Error fetching auto marks:', error);
        throw error;
    }
};

// Функция для получения списка моделей по марке
export const getAutoModels = async (markId) => {
    try {
        const response = await apiClient.get(`/catalog/get-auto-model`, { params: { markId } });
        return response.data;
    } catch (error) {
        console.error('Error fetching auto models:', error);
        throw error;
    }
};

// Функция для получения списка годов по модели и марке
export const getAutoYears = async (groupCode, markId) => {
    try {
        const response = await apiClient.get(`/catalog/get-auto-year`, { params: { groupCode, markId } });
        return response.data;
    } catch (error) {
        console.error('Error fetching auto years:', error);
        throw error;
    }
};
