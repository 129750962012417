import {Sheet} from "react-modal-sheet";
import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";


const CancelOrderBottomSheet = ({isOpen, setOpen}) => {
    const navigate = useNavigate();
    const ref = useRef();

    const [selectedReason, setSelectedReason] = useState(null)
    const [height, setHeight] = useState(window.innerHeight-100);

    const [cancelSuccess, setCancelSuccess] = useState(false)

    const [reasonsList, setReasonsList] = useState([
        {id: 1, title: 'Не устроили сроки'},
        {id: 2, title: 'Уже купил(а) у вас'},
        {id: 3, title: 'Купил(а) в другой компании'},
        {id: 4, title: 'Ошибка с выбором'},
        {id: 5, title: 'Проблемы с кредитом/рассрочкой'},
        {id: 6, title: 'Не устроила цена'},
        {id: 7, title: 'Передумал покупать'},
    ]);


    const snapTo = (i) => ref.current?.snapTo(i);

    return (
        <Sheet
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            initialSnap={0}
            snapPoints={[height, 200, 400, 0]}
        >
            <Sheet.Container>

                <Sheet.Header />
                <Sheet.Content>

                    <div className={'cancel-order-bottomshet-wrapper'}>
                        {!cancelSuccess ?

                            <>
                                <div className={'cancel-order-bottomshet-container'}>

                                    <h1 className={'cancel-order-bottomshet-title'}>Отмена заказа</h1>
                                    <h2 className={'cancel-order-bottomshet-subtitle'}>Выберите причину</h2>


                                    <div className={'reasons-list-wrapper'}>
                                        {reasonsList?.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    onClick={() => {
                                                        setSelectedReason(item?.id);
                                                    }}
                                                    className={'reasons-list-item'}
                                                >
                                                    <div className={'custom-checkbox'}>
                                                        {selectedReason == item?.id &&
                                                            <div className={'custom-checkbox-active'}>

                                                            </div>
                                                        }
                                                    </div>

                                                    <span>{item?.title}</span>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    {selectedReason &&
                                        <textarea className={'cancel-order-textarea'}
                                                  placeholder={'Расскажите подробнее'} name="" id="" cols="30"
                                                  rows="10"></textarea>
                                    }

                                </div>
                                <div className={'bottom-btns-wrapper'} style={{padding: 0, paddingBottom: 0}}>
                                <button
                                        style={{flex: 1}}
                                        onClick={() => {
                                            setCancelSuccess(false);
                                            setOpen(false)
                                            setTimeout(()=>{
                                                setHeight(window.innerHeight-100)
                                                setSelectedReason(null)
                                            }, 1000)

                                        }}
                                        className={'simple-btn'}
                                    >
                                        Закрыть
                                    </button>
                                    <button
                                        style={{opacity: selectedReason ? 1 : 0.5}}
                                        onClick={() => {
                                            setCancelSuccess(true);
                                            setHeight(200)
                                        }}
                                        className={'red-btn'}
                                    >
                                        Отменить заказ
                                    </button>
                                </div>
                            </>

                            :

                            <div className={'cancel-success-wrapper'}>
                                <div className={'cancel-success-message'}>
                                    Спасибо! Мы передали информацию менеджеру
                                </div>
                                <div className={'bottom-btns-wrapper'} style={{padding: 0, paddingBottom: 0}}>
                                    <button
                                        style={{flex: 1}}
                                        onClick={() => {
                                            setCancelSuccess(false);
                                            setOpen(false)
                                            setTimeout(()=>{
                                                setHeight(window.innerHeight-100)
                                                setSelectedReason(null)
                                            }, 1000)
                                        }}
                                        className={'simple-btn'}
                                    >
                                        Закрыть
                                    </button>
                                </div>
                            </div>

                        }

                    </div>

                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop/>
</Sheet>
)
}

export default CancelOrderBottomSheet
