import React, { useState, useEffect } from 'react';
import {DiscountActiveIcon, DiscountNoActiveIcon} from "../../assets/svg/icons";

const CountdownTimer = () => {
    // Initial time in seconds (4 hours)
    const initialTime = 4 * 60 * 60;
    // const initialTime = 30;

    // Retrieve start time from localStorage, or set it to null initially
    const isStartedTimer = () => {
        const savedStartTime = localStorage.getItem('timerStart');
        return savedStartTime ? true : false;
    };
    const getStartTime = () => {
        const savedStartTime = localStorage.getItem('timerStart');
        return savedStartTime ? parseInt(savedStartTime) : null;
    };

    const [startTime, setStartTime] = useState(getStartTime);
    const [timeLeft, setTimeLeft] = useState(initialTime);

    useEffect(() => {
        let timerInterval;

        if (startTime) {
            // Calculate time left based on the start time in localStorage
            const calculateTimeLeft = () => {
                const currentTime = Date.now();
                const elapsedTime = Math.floor((currentTime - startTime) / 1000);
                const newTimeLeft = initialTime - elapsedTime;
                setTimeLeft(newTimeLeft > 0 ? newTimeLeft : 0);

                // Clear localStorage and interval if time is up
                if (newTimeLeft <= 0) {
                    localStorage.removeItem('timerStart');
                    clearInterval(timerInterval);
                }
            };

            // Start the countdown and update it every second
            timerInterval = setInterval(calculateTimeLeft, 1000);
        }

        // Cleanup interval on component unmount
        return () => clearInterval(timerInterval);
    }, [startTime]);

    // Format time left in HH:MM:SS format
    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600).toString().padStart(2, '0');
        const mins = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
        const secs = (seconds % 60).toString().padStart(2, '0');
        return `${hrs}:${mins}:${secs}`;
    };

    // Handle button click to start the timer
    const handleStartTimer = () => {
        const currentTime = Date.now();
        setStartTime(currentTime);
        localStorage.setItem('timerStart', currentTime.toString());
        setTimeLeft(initialTime); // Reset the time left to the initial time
    };

    return (
        <div>


            {isStartedTimer() ?

                <button  className={'main-discount-btn-active'}>
                    <div className={'main-discount-btn-left'}>
                        <DiscountActiveIcon/>
                    </div>

                    <div className={'main-discount-btn-right'}>
                        <span>Скидки активированы</span>
                        <span>Осталось: {formatTime(timeLeft)}</span>
                    </div>
                </button>
                :

                <button onClick={handleStartTimer} className={'main-discount-btn'}>
                    <div className={'main-discount-btn-left'}>
                        <DiscountNoActiveIcon/>
                    </div>

                    <div className={'main-discount-btn-right'}>
                        <span>Активировать скидки в центре</span>
                        <span>по дисконтной карте на 4 часа</span>
                    </div>
                </button>
            }


        </div>
    );
};

export default CountdownTimer;
