import React, { useEffect, useState } from 'react';
import { useTelegram } from '../../../TelegramContext';
import { useNavigate } from "react-router-dom";
import '../../../assets/css/style.css';
import Loader from "../../../components/Loader";
import useTranslation from "../../../hooks/useTranslation";
import Header from "../../../components/Header";
import { Requiredicon } from "../../../assets/svg/icons";
import Dropdown from "../../../components/Dropdown";
import { useAutoData } from "../../../hooks/useAutoData";
import { useGuarantees } from "../../../hooks/useGuarantees"; // Импортируем хук для работы с гарантиями
import InputMask from 'react-input-mask'; // Импортируем библиотеку для маски

function ExtendedWarrantyActivateForm() {
    const { user, triggerVibration, webApp } = useTelegram();
    const navigate = useNavigate();
    const { translation } = useTranslation();

    const {
        marksLoading,
        marksData,
        marksError,
        fetchAutoMarks,

        modelsLoading,
        modelsData,
        modelsError,
        fetchAutoModels,

        yearsLoading,
        yearsData,
        yearsError,
        fetchAutoYears,
    } = useAutoData();

    const {
        activateUserGuarantee,
        loadingActivation,
        activationResult,
        errorActivation
    } = useGuarantees(); // Используем хук для активации гарантии

    const [name, setName] = useState('Artem');
    const [surname, setSurname] = useState('Hakobjanyan');
    const [email, setEmail] = useState('test@gmail.com');
    const [phone, setPhone] = useState('+7(985)385-21-38');
    const [code, setCode] = useState('116 566 229 551');
    const [gosNumber, setGosNumber] = useState('31231231321');

    const [selectedMark, setSelectedMark] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);

    const [isFormValid, setIsFormValid] = useState(false);
    const [isPhoneValid, setIsPhoneValid] = useState(false);

    // Проверка валидности номера телефона
    useEffect(() => {
        const phoneRegex = /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/;
        setIsPhoneValid(phoneRegex.test(phone));
    }, [phone]);

    useEffect(() => {
        if (errorActivation) {
            console.log(errorActivation, 'errorActivation')

        }
    }, [errorActivation]);

    useEffect(() => {
        if (activationResult) {
            navigate('/extended-warranty-list')
            console.log(errorActivation, 'errorActivation')

        }
    }, [activationResult]);

    // Проверка на заполненность всех полей
    useEffect(() => {
        const isFormComplete = name && surname && email && isPhoneValid && code && gosNumber && selectedMark && selectedModel && selectedYear;
        setIsFormValid(isFormComplete);
    }, [name, surname, email, isPhoneValid, code, gosNumber, selectedMark, selectedModel, selectedYear]);

    const handleActivate = () => {
        if (!isFormValid) {
            return false;
        }
        // Формируем данные для отправки в API
        const guaranteeData = {
            name,
            lastName: surname,
            email,
            phone,
            mark: selectedMark.label,
            model: selectedModel.label,
            year: selectedYear.label,
            registrationNumber: gosNumber,
            agree: true,
            code
        };

        // Вызываем функцию активации гарантии
        activateUserGuarantee(guaranteeData);
    };

    if (marksLoading) {
        return <Loader />;
    }

    return (
        <div className="wrapper">
            <Header />

            <div className={'top-title-wrapper'}>
                <div className={'top-title-text'}>Активировать гарантию</div>
            </div>

            <h2 className={'form-title'}>Личная информация</h2>

            <div className={'input-wrapper'}>
                <label htmlFor="name">Имя</label>
                <input
                    id={'name'}
                    className={'input'}
                    placeholder={'Введите имя'}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>

            <div className={'input-wrapper'}>
                <label htmlFor="surname">Фамилия</label>
                <input
                    id={'surname'}
                    className={'input'}
                    placeholder={'Введите фамилию'}
                    type="text"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                />
            </div>

            <div className={'input-wrapper'}>
                <label htmlFor="email">Почта</label>
                <input
                    id={'email'}
                    className={'input'}
                    placeholder={'Введите почту'}
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>

            <div className={'input-wrapper'}>
                <label htmlFor="phone">Телефон</label>
                <InputMask
                    mask="+7(999)999-99-99"
                    value={phone}
                    onChange={(e) => {
                        console.log(e.target.value, 'e.target.value');
                        setPhone(e.target.value);
                    }}
                >
                    {(inputProps) => (
                        <input
                            {...inputProps}
                            id={'phone'}
                            className={'input'}
                            placeholder={'Введите телефон'}
                            type="text"
                        />
                    )}
                </InputMask>
                {/*{!isPhoneValid && phone && <p className="error-text">Неверный формат номера телефона</p>}*/}
            </div>

            <h2 className={'form-title'}>Автомобиль</h2>

            <Dropdown
                isActive={marksData ? true : false}
                defaultText={'Укажите марку авто'}
                label={<div>Марка <Requiredicon /></div>}
                options={marksData || []}
                onOptionSelect={(selectedVal) => {
                    if (selectedVal) {
                        fetchAutoModels(selectedVal?.value);
                        setSelectedModel(null);
                        setSelectedYear(null);
                    }
                    setSelectedMark(selectedVal);
                }}
            />

            <Dropdown
                isActive={modelsData ? true : false}
                defaultText={'Укажите модель авто'}
                label={<div>Модель <Requiredicon /></div>}
                options={modelsData || []}
                onOptionSelect={(selectedVal) => {
                    if (selectedVal) {
                        fetchAutoYears(selectedVal?.value, selectedMark?.value);
                    }
                    setSelectedYear(null);
                    setSelectedModel(selectedVal);
                }}
            />

            <Dropdown
                isActive={yearsData ? true : false}
                defaultText={'Укажите год выпуска'}
                label={<div>Год выпуска <Requiredicon /></div>}
                options={yearsData || []}
                onOptionSelect={(selectedVal) => {
                    setSelectedYear(selectedVal);
                }}
            />

            <div className={'input-wrapper'}>
                <label htmlFor="gosNumber">Гос. номер <Requiredicon /></label>
                <input
                    id={'gosNumber'}
                    className={'input'}
                    placeholder={'Введите гос. номер'}
                    type="text"
                    value={gosNumber}
                    onChange={(e) => setGosNumber(e.target.value)}
                />
            </div>

            <div className={'input-wrapper'}>
                <label htmlFor="code">Код <Requiredicon /></label>
                <input
                    id={'code'}
                    className={'input'}
                    placeholder={'Код'}
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                />
            </div>

            <div className={'btns-wrapper'}>
                <button
                    onClick={() => {
                        navigate(-1);
                    }}
                    className={'button'}
                >
                    Отмена
                </button>

                <button
                    style={{ opacity: isFormValid && !loadingActivation ? 1 : 0.5 }}
                    onClick={handleActivate}
                    className={'button'}
                    disabled={!isFormValid || loadingActivation}
                >
                    {loadingActivation ? 'Активация...' : 'Активировать'}
                </button>
            </div>

            {/* {activationResult && <p>Гарантия успешно активирована!</p>} */}
            {/* {errorActivation && <p>Ошибка активации: {errorActivation.message}</p>} */}
        </div>
    );
}

export default ExtendedWarrantyActivateForm;
