import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import Loader from "../../components/Loader";
import useTranslation from "../../hooks/useTranslation";
import Header from "../../components/Header";
import useMyCars from "../../hooks/useMyCars";
import topImage from "../../assets/img/main/top2.png";
import {BottomArrowIcon, LogoIcon, PlusIcon} from "../../assets/svg/icons";
import FeedbackForm from "../../components/FeedbackForm";
import AddCarForm from "./AddCarForm";

function MyCars() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();
    const { myCarsAnswer, loadingMyCars, errorMyCars, fetchUserCars } = useMyCars();

    const [isOpenCarId,setIsOpenCarId] = useState(null);
    const [noData, setNoData] = useState(false);
    const [isOpen, setIsOpen] = useState(false);


    useEffect(()=>{
        if (myCarsAnswer) {
            if (myCarsAnswer?.data?.length > 0) {
                setNoData(false)
            } else {
                setNoData(true)
            }
            console.log(myCarsAnswer, 'myCarsAnswer')
        }
    }, [myCarsAnswer])

    if (loadingMyCars) {
        return (
            <Loader/>
        )
    }

    return (
        <div className="wrapper wrapper-flex" style={{padding: 0}}>

            <Header/>
            {noData ?
                <div className="top-block" style={{height: 70}}>
                    <Link to={'/'} className="top-block-logo">
                        <LogoIcon/>
                    </Link>
                </div>
                :

                <div className="top-block" style={{backgroundImage: `url(${topImage})`, borderRadius: 0}}>
                    <Link to={'/'} className="top-block-logo">
                        <LogoIcon/>
                    </Link>
                </div>
            }


            <div
                className={'padding-block'}
                style={{
                    flex: 1,
                    borderRadius: "10px 10px 0 0 ",
                    position: 'relative',
                    top: -10,
                    background: "white",
                    overflow: "scroll"
                }}
            >
                <div className={'top-title-wrapper'}>

                    {noData ?
                        <div className={'top-title-text'} style={{marginLeft:0}}>
                            Пока нет авто
                        </div>
                        :
                        <div className={'top-title-text'} style={{marginLeft:0}}>
                            Мои авто
                        </div>
                    }

                </div>

                <div className={'list-wrapper'}>

                    {/*{myCarsAnswer?.data && myCarsAnswer?.data.map((item, index) => {*/}
                    {/*    return (*/}
                    {/*        <div className={'list-item'}>*/}
                    {/*            <div className={'list-item-center'}>*/}
                    {/*                <span>{item?.carMark}</span>*/}
                    {/*            </div>*/}
                    {/*            <div className={'list-item-bottom'}>*/}
                    {/*                <span>Гос. номер:</span>*/}
                    {/*                <span>{item?.gtd}</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*    )*/}
                    {/*})}*/}

                    {noData ?
                        <>
                            <img style={{width: '100%'}} src={require('../../assets/img/myauto/noauto.png')} alt=""/>
                        </>
                        :
                        <>
                            {myCarsAnswer?.data && myCarsAnswer?.data.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            let id = index;
                                            setIsOpenCarId(isOpenCarId == id ? null : id)
                                        }}
                                        className={`list-item ${isOpenCarId == index ? 'is-open' : ''}`}
                                        style={{padding: 0}}
                                    >

                                        <div className={'list-item-wrapper'}>
                                            <div className={'list-item-center'}>
                                                <span>{item?.carMark}</span>
                                            </div>

                                            <div className={'list-item-bottom'}>
                                                <span>Гос. номер:</span>
                                                <span>{item?.gtd}</span>
                                            </div>

                                            <BottomArrowIcon className={'bottom-arrow'}/>
                                        </div>

                                        <div className={'list-item-bottom-hide-block'}>
                                            <button
                                                onClick={(e)=>{
                                                    e.stopPropagation()
                                                }}
                                            >
                                                Удалить авто
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}


                        </>
                    }


                </div>


                {noData &&
                    <FeedbackForm title={'Насколько удобен личный кабинет?'}/>
                }
            </div>


            <div className={'bottom-btns-wrapper'}>
                <button
                    onClick={() => {
                        navigate(-1)
                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>

                <button
                    onClick={() => {
                        setIsOpen(true)
                    }}
                    className={'red-btn'}

                >
                    <PlusIcon style={{marginRight: 10}}/>
                    Добавить ещё авто
                </button>

            </div>

            <AddCarForm
                isOpen={isOpen}
                setOpen={setIsOpen}
            />

        </div>
    );
}

export default MyCars;
