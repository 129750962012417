import { useState, useEffect } from 'react';
import { getWillStorage } from '../utils/api/wilStorageApi'; // Импортируем функцию API

export const useWilStorage = () => {
    const [wilStorageAnswer, setWilStorageAnswer] = useState(null); // Состояние для хранения данных заказов
    const [loadingWilStorage, setLoadingWilStorage] = useState(false); // Состояние для индикации загрузки
    const [errorWilStorage, setErrorWilStorage] = useState(null); // Состояние для хранения ошибок

    // Функция для получения заказов
    const fetchWilStorage = async () => {
        setLoadingWilStorage(true); // Включаем индикацию загрузки
        setErrorWilStorage(null); // Сбрасываем ошибки

        try {
            const data = await getWillStorage(); // Вызов API для получения заказов
            setWilStorageAnswer(data); // Устанавливаем данные заказов
        } catch (err) {
            setErrorWilStorage(err); // Устанавливаем ошибку, если запрос не удался
        } finally {
            setLoadingWilStorage(false); // Выключаем индикацию загрузки
        }
    };

    useEffect(() => {
        fetchWilStorage(); // Автоматически вызываем запрос при монтировании компонента
    }, []);

    return { wilStorageAnswer, loadingWilStorage, errorWilStorage, fetchWilStorage }; // Возвращаем заказы, состояние загрузки, ошибку и функцию обновления
};
