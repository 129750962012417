import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import Loader from "../../components/Loader";
import useTranslation from "../../hooks/useTranslation";
import Header from "../../components/Header";
import useMyCars from "../../hooks/useMyCars";
import topImage from "../../assets/img/main/top2.png";
import {BottomArrowIcon, LogoIcon, PlusIcon} from "../../assets/svg/icons";
import FeedbackForm from "../../components/FeedbackForm";
import {Switch} from "@mui/material";
import SettingsBottomSheet from "./include/SettingsBottomSheet";

function Settings() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();
    const { myCarsAnswer, loadingMyCars, errorMyCars, fetchUserCars } = useMyCars();

    const [phoneCallAllowed,setPhoneCallAllowed] = useState(false);
    const [phoneCallEnable,setPhoneCallEnable] = useState(false);

    const [smsMailingsAllowed,setSmsMailingsAllowed] = useState(false);
    const [smsMailingsEnable, setSmsMailingsEnable] = useState(false);

    const [emailMailingsAllowed,setEmailMailingsAllowed] = useState(false);
    const [emailMailingsEnable, setEmailMailingsEnable] = useState(false);

    const [messengerMailingsAllowed,setMessengerMailingsAllowed] = useState(false);
    const [messengerMailingsEnable, setMessengerMailingsEnable] = useState(false);

    const [modalType, setModalType] = useState(null);
    const [isOpen, setIsOpen] = useState(null);


    useEffect(()=>{
        if (myCarsAnswer) {
            console.log(myCarsAnswer, 'myCarsAnswer')
        }
    }, [myCarsAnswer])

    // if (loadingMyCars) {
    //     return (
    //         <Loader/>
    //     )
    // }


    return (
        <div className="wrapper wrapper-flex" style={{padding: 0}}>

            <Header/>
            <div className="top-block" style={{backgroundImage: `url(${topImage})`, borderRadius: 0}}>
                <Link to={'/'} className="top-block-logo">
                    <LogoIcon/>
                </Link>
            </div>

            <div
                className={'padding-block'}
                style={{
                    flex: 1,
                    borderRadius: "10px 10px 0 0 ",
                    position: 'relative',
                    top: -10,
                    background: "white",
                    overflow: "scroll"
                }}
            >
                <div className={'top-title-wrapper'}>

                    <div className={'top-title-text'} style={{marginLeft: 0}}>
                        Настройки
                    </div>
                </div>

                <div className={'list-wrapper'}>

                    {/*Телевонные звонки*/}
                    <div className={`list-item is-open`} style={{padding: 0}}>

                        <div className={'list-item-wrapper'}>
                            <div className={'list-item-center'}>
                                <span>Телефонные звонки</span>
                            </div>

                        </div>

                        <div className={'list-item-bottom-hide-block settings'} style={{display: "flex", justifyContent: "space-between", alignItems:'center'}}>

                            {phoneCallAllowed ?
                                <>
                                    <p>Даю согласие на телефонные звонки</p>
                                    <Switch
                                        color={'success'}
                                        checked={phoneCallEnable}
                                        onChange={(e) => {
                                            setPhoneCallEnable(prevState => !prevState)
                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                </>
                                :
                                <>
                                    <p>Еще не назначено</p>
                                    <button
                                        onClick={() => {
                                            setIsOpen(true)
                                            setModalType('phone')
                                        }}
                                    >
                                        Назначить
                                    </button>
                                </>
                            }
                        </div>
                    </div>

                    {/*СМС-рассылки*/}
                    <div className={`list-item is-open`} style={{padding: 0}}>

                        <div className={'list-item-wrapper'}>
                            <div className={'list-item-center'}>
                                <span>СМС-рассылки</span>
                            </div>

                        </div>

                        <div className={'list-item-bottom-hide-block settings'} style={{display: "flex", justifyContent: "space-between", alignItems:'center'}}>

                            {smsMailingsAllowed ?
                                <>
                                    <p>Даю согласие на смс-рассылки</p>
                                    <Switch
                                        color={'success'}
                                        checked={smsMailingsEnable}
                                        onChange={(e) => {
                                            setSmsMailingsEnable(prevState => !prevState)
                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                </>
                                :
                                <>
                                    <p>Еще не назначено</p>
                                    <button
                                        onClick={() => {
                                            setIsOpen(true)
                                            setModalType('sms')
                                        }}
                                    >
                                        Назначить
                                    </button>
                                </>
                            }
                        </div>
                    </div>


                    {/*Email-рассылки*/}
                    <div className={`list-item is-open`} style={{padding: 0}}>

                        <div className={'list-item-wrapper'}>
                            <div className={'list-item-center'}>
                                <span>Email-рассылки</span>
                            </div>

                        </div>

                        <div className={'list-item-bottom-hide-block settings'} style={{display: "flex", justifyContent: "space-between", alignItems:'center'}}>

                            {emailMailingsAllowed ?
                                <>
                                    <p>Даю согласие на Email-рассылки</p>
                                    <Switch
                                        color={'success'}
                                        checked={emailMailingsEnable}
                                        onChange={(e) => {
                                            setEmailMailingsEnable(prevState => !prevState)
                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                </>
                                :
                                <>
                                    <p>Еще не назначено</p>
                                    <button
                                        onClick={() => {
                                            setIsOpen(true)
                                            setModalType('email')
                                        }}
                                    >
                                        Назначить
                                    </button>
                                </>
                            }
                        </div>
                    </div>

                    {/*Мессенджер-рассылки*/}
                    <div className={`list-item is-open`} style={{padding: 0}}>

                        <div className={'list-item-wrapper'}>
                            <div className={'list-item-center'}>
                                <span>Мессенджер-рассылки</span>
                            </div>

                        </div>

                        <div className={'list-item-bottom-hide-block settings'} style={{display: "flex", justifyContent: "space-between", alignItems:'center'}}>

                            {messengerMailingsAllowed ?
                                <>
                                    <p>Даю согласие на мессенджер-рассылки</p>
                                    <Switch
                                        color={'success'}
                                        checked={messengerMailingsEnable}
                                        onChange={(e) => {
                                            setMessengerMailingsEnable(prevState => !prevState)
                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                </>
                                :
                                <>
                                    <p>Еще не назначено</p>
                                    <button
                                        onClick={() => {
                                            setIsOpen(true)
                                            setModalType('messenger')
                                        }}
                                    >
                                        Назначить
                                    </button>
                                </>
                            }
                        </div>
                    </div>


                </div>


                <FeedbackForm title={'Насколько удобен личный кабинет?'}/>
            </div>


            <div className={'bottom-btns-wrapper'}>
                <button
                    style={{flex:1}}
                    onClick={() => {
                        navigate(-1)
                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>

            </div>


            <SettingsBottomSheet
                isOpen={isOpen}
                setOpen={setIsOpen}
                modalType={modalType}
                setPhoneCallAllowed={setPhoneCallAllowed}
                setSmsMailingsAllowed={setSmsMailingsAllowed}
                setEmailMailingsAllowed={setEmailMailingsAllowed}
                setMessengerMailingsAllowed={setMessengerMailingsAllowed}
            />

        </div>
    );
}

export default Settings;
