import React, { useState } from 'react';

const Dropdown = ({ options, label, onOptionSelect, defaultText, isActive }) => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleChange = (event) => {
        let item = event.target.value;
        if (item != '') {
            item = JSON.parse(item);

            setSelectedOption( event.target.value);
            onOptionSelect(item); // Вызываем функцию, переданную через пропсы
        } else {
            setSelectedOption( event.target.value);
            onOptionSelect(item?.value || ''); // Вызываем функцию, переданную через пропсы
        }

    };

    return (
        <div className={'select-wrapper'}>
            <label htmlFor="dropdown">{label}</label>

            <select disabled={!isActive} id="dropdown" value={selectedOption} onChange={handleChange}>
                <option value="">{defaultText}</option>
                {options.map((option, index) => (
                    <option key={index} value={JSON.stringify(option)}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;
