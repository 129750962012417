import React, { createContext, useContext, useState } from 'react';

const SingleContext = createContext();

export const SingleProvider = ({ children }) => {
    const [selectedSingleData, setSelectedSingleData] = useState(null); // Состояние для хранения выбранного заказа

    return (
        <SingleContext.Provider value={{ selectedSingleData, setSelectedSingleData }}>
            {children}
        </SingleContext.Provider>
    );
};

// Хук для использования контекста
export const useSingleData = () => useContext(SingleContext);
