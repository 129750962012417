import apiClient from '../apiClient';

// Функция для получения профиля пользователя
export const getUserCars = async () => {
    try {
        const response = await apiClient.get('/user/auto', {});
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};
