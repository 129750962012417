import { useState, useEffect } from 'react';
import { getUserProfile } from '../utils/api/profileApi'; // Импорт функции получения профиля

const useUserProfile = () => {
    const [profileAnswer, setProfileAnswer] = useState(null); // Состояние для хранения профиля
    const [loadingProfile, setLoadingProfile] = useState(false); // Состояние для индикации загрузки
    const [errorProfile, setErrorProfile] = useState(null); // Состояние для хранения ошибок

    // Функция для получения профиля
    const fetchUserProfile = async () => {
        setLoadingProfile(true);
        setErrorProfile(null);
        try {
            const data = await getUserProfile(); // Получение профиля через API
            setProfileAnswer(data); // Сохранение профиля в состоянии
        } catch (err) {
            setErrorProfile(err); // Сохранение ошибки в состоянии
        } finally {
            setLoadingProfile(false); // Сброс состояния загрузки
        }
    };

    useEffect(() => {
        fetchUserProfile(); // Автоматически загружаем профиль при монтировании компонента
    }, []);

    return {
        profileAnswer,
        loadingProfile,
        errorProfile,
        fetchUserProfile , // Функция для обновления профиля по запросу
    };
};

export default useUserProfile;
