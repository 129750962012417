import {Sheet} from "react-modal-sheet";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAutoData} from "../../../hooks/useAutoData";
import {useGuarantees} from "../../../hooks/useGuarantees";
import {BlockedPhoneIcon, PlusIcon, Requiredicon} from "../../../assets/svg/icons";
import InputMask from "react-input-mask";
import Dropdown from "../../../components/Dropdown";


const AddCarForm = ({isOpen, setOpen}) => {

    const navigate = useNavigate();
    const ref = useRef();

    const {
        marksLoading,
        marksData,
        marksError,
        fetchAutoMarks,

        modelsLoading,
        modelsData,
        modelsError,
        fetchAutoModels,

        yearsLoading,
        yearsData,
        yearsError,
        fetchAutoYears,
    } = useAutoData();

    const {
        activateUserGuarantee,
        loadingActivation,
        activationResult,
        errorActivation
    } = useGuarantees();

    const [height, setHeight] = useState(window.innerHeight - 100);





    const [vinCode, setVinCode] = useState('');
    const [gosNumber, setGosNumber] = useState('');

    const [selectedMark, setSelectedMark] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedModification, setSelectedModification] = useState(null);

    const [isFormValid, setIsFormValid] = useState(false);

    // Проверка валидности номера телефона
    useEffect(() => {
        console.log(height, 'height')
    }, []);

    useEffect(() => {
        if (errorActivation) {
            console.log(errorActivation, 'errorActivation')
            alert(errorActivation?.errorMessage)
        }
    }, [errorActivation]);

    useEffect(() => {
        if (activationResult) {
            navigate('/extended-warranty-list')
            console.log(errorActivation, 'errorActivation')

        }
    }, [activationResult]);

    // Проверка на заполненность всех полей
    useEffect(() => {
        const isFormComplete = vinCode && gosNumber && selectedMark && selectedModel && selectedYear && selectedModification;
        setIsFormValid(isFormComplete);
    }, [ selectedMark, selectedModel, selectedYear, vinCode, gosNumber, selectedModification]);


    const handleActivate = () => {
        if (!isFormValid) {
            return false;
        }
        // // Формируем данные для отправки в API
        // const guaranteeData = {
        //     name,
        //     lastName: surname,
        //     email,
        //     phone,
        //     mark: selectedMark.label,
        //     model: selectedModel.label,
        //     year: selectedYear.label,
        //     registrationNumber: gosNumber,
        //     agree: true,
        //     code
        // };

        // Вызываем функцию активации гарантии
        // activateUserGuarantee(guaranteeData);
    };

    const snapTo = (i) => ref.current?.snapTo(i);

    return (
        <Sheet
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            initialSnap={0}
            snapPoints={[height, 400, 200, 0]}
        >
            <Sheet.Container>

                <Sheet.Header />
                <Sheet.Content>

                    {/*<div style={{width:'100%', height: '100%', background: 'red', overflow: 'scroll'}}>*/}

                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}
                    {/*    <div  style={{width:'100%', height: 100, marginBottom:10, background: 'green'}}></div>*/}

                    {/*</div>*/}

                    <div className={'extended-bottomshet-wrapper'}>

                        <div className={'extended-bottomshet-container'}>

                            <h1 className={'extended-bottomshet-main-title'}>Добавьте новый автомобиль</h1>


                            <Dropdown
                                isActive={marksData ? true : false}
                                defaultText={'Укажите марку авто'}
                                label={<div>Марка <Requiredicon/></div>}
                                options={marksData || []}
                                onOptionSelect={(selectedVal) => {
                                    if (selectedVal) {
                                        fetchAutoModels(selectedVal?.value);
                                        setSelectedModel(null);
                                        setSelectedYear(null);
                                    }
                                    setSelectedMark(selectedVal);
                                }}
                            />

                            <Dropdown
                                isActive={modelsData ? true : false}
                                defaultText={'Укажите модель авто'}
                                label={<div>Модель <Requiredicon/></div>}
                                options={modelsData || []}
                                onOptionSelect={(selectedVal) => {
                                    if (selectedVal) {
                                        fetchAutoYears(selectedVal?.value, selectedMark?.value);
                                    }
                                    setSelectedYear(null);
                                    setSelectedModel(selectedVal);
                                }}
                            />

                            <Dropdown
                                isActive={yearsData ? true : false}
                                defaultText={'Укажите год выпуска'}
                                label={<div>Год выпуска <Requiredicon/></div>}
                                options={yearsData || []}
                                onOptionSelect={(selectedVal) => {
                                    setSelectedYear(selectedVal);
                                }}
                            />

                            <Dropdown
                                isActive={selectedYear ? true : false}
                                defaultText={'Модификация'}
                                label={<div>Модификация <Requiredicon/></div>}
                                options={[{label: 'тест1', value: 'тест1'}]}
                                onOptionSelect={(selectedVal) => {
                                    setSelectedModification(selectedVal);
                                }}
                            />


                            <p className={'extended-subtitle'}>Данные автомобиля</p>

                            <div className={'input-wrapper'}>
                                <input
                                    id={'gosNumber'}
                                    className={'input'}
                                    placeholder={'Введите гос. номер автомобиля'}
                                    type="text"
                                    value={gosNumber}
                                    onChange={(e) => setGosNumber(e.target.value)}
                                />
                            </div>

                            <div className={'extended-input-wrapper'}>
                                <input
                                    id={'name'}
                                    className={'input'}
                                    placeholder={'Введите VIN'}
                                    type="text"
                                    value={vinCode}
                                    onChange={(e) => setVinCode(e.target.value)}
                                />
                            </div>


                        </div>


                        <div className={'bottom-btns-wrapper'} style={{padding: 15, paddingBottom: 15}}>
                            <button
                                style={{flex: 1}}
                                onClick={() => {
                                    setOpen(false)
                                    setTimeout(() => {
                                        setHeight(window.innerHeight - 100)
                                    }, 1000)
                                }}
                                className={'simple-btn'}
                            >
                                Отмена
                            </button>

                            <button
                                style={{flex: 1, opacity: isFormValid && !loadingActivation ? 1 : 0.5}}
                                onClick={() => {
                                    handleActivate()
                                }}
                                className={'red-btn'}
                            >
                                <PlusIcon/>
                                Добавить авто
                            </button>
                        </div>
                    </div>

                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop/>
        </Sheet>
    )
}

export default AddCarForm
