import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import Loader from "../../components/Loader";
import useTranslation from "../../hooks/useTranslation";
import Header from "../../components/Header";
import useUserProfile from "../../hooks/useUserProfile";
import {BottomArrowIcon, LogoIcon, PlusIcon} from "../../assets/svg/icons";
import topImage from "../../assets/img/main/top2.png";

function Profile() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();
    const { profileAnswer, loadingProfile, errorProfile, fetchUserProfile} = useUserProfile();

    const [firstName,setFirstName] = useState('');
    const [surName,setSurName] = useState('');
    const [lastName,setLastName] = useState('');
    const [phone,setPhone] = useState('');
    const [email,setEmail] = useState('');

    useEffect(()=>{
        fetchUserProfile()
    }, [])

    useEffect(()=>{

        if (errorProfile) {
            console.log(errorProfile, 'errorProfile')

        }
    }, [errorProfile])

    useEffect(()=>{

        if (profileAnswer) {
            console.log(profileAnswer?.data, 'profileAnswer')

            setFirstName(profileAnswer?.data?.firstName)
            setSurName(profileAnswer?.data?.surName)
            setLastName(profileAnswer?.data?.lastName)
            setPhone(profileAnswer?.data?.phone)
            setEmail(profileAnswer?.data?.email)
        }
    }, [profileAnswer])

    // if (loadingProfile) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (


        <div className="wrapper wrapper-flex" style={{padding: 0}}>

            <Header/>

            <div className="top-block" style={{backgroundImage: `url(${topImage})`, borderRadius: 0}}>
                <Link to={'/'} className="top-block-logo">
                    <LogoIcon/>
                </Link>
            </div>

            <div
                className={'padding-block'}
                style={{
                    flex: 1,
                    borderRadius: "10px 10px 0 0 ",
                    position: 'relative',
                    top: -10,
                    background: "white"
                }}
            >
                <div className={'top-title-wrapper'}>

                    <div className={'top-title-text'} style={{marginLeft: 0}}>
                        Мои данные
                    </div>

                </div>

                <div className={'list-wrapper'}>


                    <div className="profile-input-wrapper">
                        <input
                            placeholder={'Имя'}
                            value={firstName}
                            type="text"
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                        />
                    </div>


                    <div className="profile-input-wrapper">
                        <input
                            placeholder={'Фамилия'}
                            value={surName}
                            type="text"
                            onChange={(e) => {
                                setSurName(e.target.value);
                            }}
                        />
                    </div>


                    <div className="profile-input-wrapper">
                        <input
                            placeholder={'Отчество'}
                            value={lastName}
                            type="text"
                            onChange={(e) => {
                                setLastName(e.target.value);
                            }}
                        />
                    </div>

                    <div className="profile-input-wrapper">
                        <input
                            placeholder={'Email'}
                            value={email}
                            type="text"
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                    </div>

                    <div className="profile-input-wrapper">
                        <input
                            placeholder={'Номер телефона'}
                            value={phone}
                            type="text"
                            onChange={(e) => {
                                setPhone(e.target.value);
                            }}
                        />
                    </div>


                </div>
            </div>


            <div className={'bottom-btns-wrapper'} style={{display: 'flex'}}>
                <button
                    style={{flex:1}}
                    onClick={() => {
                        navigate(-1)
                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>

                <button
                    style={{flex:1}}
                    onClick={() => {
                    }}
                    className={'red-btn'}

                >
                    Сохранить
                </button>

            </div>


        </div>
    );
}

export default Profile;
