import apiClient from '../apiClient'; // Предполагаем, что у вас есть axios instance

// Функция для получения списка гарантий пользователя
export const getGuarantees = async () => {
    try {
        const response = await apiClient.get('/user/guarantees'); // GET-запрос к API
        return response.data;
    } catch (error) {
        console.error('Error fetching user guarantees:', error.response?.data || error.message);
        throw error.response?.data || error;
    }
};

// Функция для активации гарантии
export const activateGuarantee = async (guaranteeData) => {
    try {
        const response = await apiClient.post('/user/guarantees/activate', guaranteeData); // POST-запрос к API
        return response.data;
    } catch (error) {
        console.error('Error activating guarantee:', error.response?.data || error.message);
        throw error.response?.data || error;
    }
};
