import apiClient from '../apiClient';

// Функция для получения профиля пользователя
export const getUserCard = async (phone) => {
    try {
        const response = await apiClient.post('/loyalty-card/get-card', {phone:phone});
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};

export const getDiscount = async () => {
    try {
        const response = await apiClient.get('/user/discount');
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};
