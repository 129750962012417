import { useState, useEffect } from 'react';
import { getUserCars } from '../utils/api/myCarsApi'; // Импорт функции получения профиля

const useMyCars = () => {
    const [myCarsAnswer, setMyCarsAnswer] = useState(null); // Состояние для хранения профиля
    const [loadingMyCars, setLoadingMyCars] = useState(false); // Состояние для индикации загрузки
    const [errorMyCars, setErrorMyCars] = useState(null); // Состояние для хранения ошибок

    // Функция для получения профиля
    const fetchUserCars = async () => {
        setLoadingMyCars(true);
        setErrorMyCars(null);
        try {
            const data = await getUserCars(); // Получение профиля через API
            setMyCarsAnswer(data); // Сохранение профиля в состоянии
        } catch (err) {
            setErrorMyCars(err); // Сохранение ошибки в состоянии
        } finally {
            setLoadingMyCars(false); // Сброс состояния загрузки
        }
    };

    useEffect(() => {
        fetchUserCars(); // Автоматически загружаем профиль при монтировании компонента
    }, []);

    return {
        myCarsAnswer,
        loadingMyCars,
        errorMyCars,
        fetchUserCars , // Функция для обновления профиля по запросу
    };
};

export default useMyCars;
