import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import Loader from "../../../components/Loader";

import Header from "../../../components/Header";
import {
    AutoIcon,
    LocationIcon,
    LogoIcon,
    Red2Icon, RedInfoIcon,
    RightArrowIcon,
    SunnyIcon,
    UserIcon, WinterIcon, YellowInfoIcon
} from "../../../assets/svg/icons";
import {useSingleData} from "../../../context/SingleContext";
import WheelStorageInfo from "../include/WheelStorageInfo";

function WheelStorageSingle() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    const [isOpen,setOpen] = useState(false);
    const [isRed,setIsRed] = useState(false);

    const { selectedSingleData, setSelectedSingleData } = useSingleData(); // Достаем функцию для установки выбранного заказа
    const [noData, setNoData] = useState(false);

    useEffect(()=>{
        if (selectedSingleData) {
            console.log(selectedSingleData, 'selectedSingleData')
        }
    }, [selectedSingleData])

    function getMonthDeclension(number) {
        const lastDigit = number % 10;
        const lastTwoDigits = number % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return `${number} месяцев`;
        } else if (lastDigit === 1) {
            return `${number} месяц`;
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            return `${number} месяца`;
        } else {
            return `${number} месяцев`;
        }
    }

    function isDateInPast(dateString) {
        // Функция для перевода месяца с русского на английский
        const convertMonth = (month) => {
            const months = {
                'января': 'January',
                'февраля': 'February',
                'марта': 'March',
                'апреля': 'April',
                'мая': 'May',
                'июня': 'June',
                'июля': 'July',
                'августа': 'August',
                'сентября': 'September',
                'октября': 'October',
                'ноября': 'November',
                'декабря': 'December'
            };
            return months[month];
        };

        // Разделяем строку на день, месяц и год
        const [day, month, year] = dateString.replace(" г.", "").split(" ");
        const englishMonth = convertMonth(month); // Переводим месяц

        // Создаем объект даты
        const givenDate = new Date(`${englishMonth} ${day}, ${year}`);
        const today = new Date(); // Сегодняшняя дата

        console.log(today , givenDate)
        // Сравниваем с сегодняшней датой
        return today > givenDate;
    }

    useEffect(()=>{
        console.log(isDateInPast("06 октября 2024 г.")); // true или false

    }, [])

    // if (selectedSingleData) {
    //     return (
    //         <Loader/>
    //     )
    // }

    return (

        <div className="wrapper wrapper-flex">

            <Header/>

            <Link to={'/'} className="top-block-logo">
                <LogoIcon/>
            </Link>

            <div className={'list-wrapper'} style={{flex: 1, marginTop: 70, overflow: 'scroll'}}>

                <div className={'list-item'} style={{background: 'unset', padding: 0, marginBottom: 30}}>
                    <div className={'list-item-center'}>
                        <div style={{display:"flex", justifyContent: "space-between"}}>
                            <span style={{fontSize: 20, fontWeight: 700, color: isDateInPast(selectedSingleData?.dataEnd) ? '#F15457' : 'black'}}>
                                # {selectedSingleData?.partyCode}
                            </span>
                            {isDateInPast(selectedSingleData?.dataEnd) &&
                                <p className={'red-message2'}>
                                    нужно забрать
                                    <Red2Icon className={'red-message2-icon'}/>
                                </p>
                            }
                        </div>

                        <div
                            onClick={()=>{
                                if (isDateInPast(selectedSingleData?.dataEnd)) {
                                    setIsRed(true);
                                } else {
                                    setIsRed(false);
                                }
                                setOpen(true)
                            }}
                            style={{flex:1, display:"flex", justifyContent: "flex-end"}}
                        >
                            {isDateInPast(selectedSingleData?.dataEnd) ?
                                <RedInfoIcon/>
                                :
                                <YellowInfoIcon/>
                            }
                        </div>
                    </div>
                    <div className={'list-item-bottom'}>
                        <span>
                            Партия хранения
                        </span>
                    </div>
                </div>


                <h1 className={'wheel-storage-title'}>Срок хранения:</h1>

                <div className={'wheel-storage-address'}>
                    {selectedSingleData?.dataStart} - {selectedSingleData?.dataEnd}
                </div>

                <h1 className={'wheel-storage-title'}>Тариф:</h1>
                <p className={'reg-for-services-val'}>{getMonthDeclension(selectedSingleData?.tariff)}</p>


                <h1 className={'wheel-storage-title'}>Кол-во колёс:</h1>
                <p className={'wheel-storage-val'}>{selectedSingleData?.quantity} шт.</p>

                <h1 className={'wheel-storage-title'}>Комплектация:</h1>
                <p className={'wheel-storage-val'}>{selectedSingleData?.equipment}</p>

                <h1 className={'wheel-storage-title'}>Наименование:</h1>
                <div className={'wheel-storage-products-list'}>
                    {selectedSingleData.products.length > 0 && selectedSingleData?.products.map((item, index)=>{
                        return (
                            <div key={index} className={'wheel-storage-products-list-item'}>
                                <div key={index} className={'wheel-storage-products-list-item-left'}>
                                    {item?.season == 0 ?
                                        <WinterIcon/>
                                        :
                                        <SunnyIcon/>
                                    }
                                </div>
                                <div key={index} className={'wheel-storage-products-list-item-right'}>
                                    <span>{item?.title}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>

            <div className={'bottom-btns-wrapper'} style={{padding: 0, paddingBottom: 20}}>
                <button
                    style={{flex:1}}
                    onClick={() => {
                        navigate(-1);
                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>
            </div>


            <WheelStorageInfo
                isOpen={isOpen}
                setOpen={setOpen}
                isRed={isRed}
            />

        </div>

    );
}

export default WheelStorageSingle;
