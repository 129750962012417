import { useState, useEffect } from 'react';
import { getUserOrders } from '../utils/api/ordersApi'; // Импортируем функцию API

export const useUserOrders = () => {
    const [orders, setOrders] = useState(null); // Состояние для хранения данных заказов
    const [loading, setLoading] = useState(false); // Состояние для индикации загрузки
    const [error, setError] = useState(null); // Состояние для хранения ошибок

    // Функция для получения заказов
    const fetchUserOrders = async () => {
        setLoading(true); // Включаем индикацию загрузки
        setError(null); // Сбрасываем ошибки

        try {
            const data = await getUserOrders(); // Вызов API для получения заказов
            setOrders(data); // Устанавливаем данные заказов
        } catch (err) {
            setError(err); // Устанавливаем ошибку, если запрос не удался
        } finally {
            setLoading(false); // Выключаем индикацию загрузки
        }
    };

    useEffect(() => {
        fetchUserOrders(); // Автоматически вызываем запрос при монтировании компонента
    }, []);

    return { orders, loading, error, fetchUserOrders }; // Возвращаем заказы, состояние загрузки, ошибку и функцию обновления
};
