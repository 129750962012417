import { useState, useEffect } from 'react';
import { getGuarantees, activateGuarantee } from '../utils/api/guaranteesApi'; // Импортируем функции API

export const useGuarantees = () => {
    const [guarantees, setGuarantees] = useState(null); // Состояние для хранения данных гарантий
    const [loadingGuarantees, setLoadingGuarantees] = useState(false); // Состояние для индикации загрузки
    const [errorGuarantees, setErrorGuarantees] = useState(null); // Состояние для хранения ошибок

    const [activationResult, setActivationResult] = useState(null); // Состояние для результата активации
    const [loadingActivation, setLoadingActivation] = useState(false); // Состояние для загрузки активации
    const [errorActivation, setErrorActivation] = useState(null); // Состояние для ошибок активации

    // Функция для получения гарантий
    const fetchGuarantees = async () => {
        setLoadingGuarantees(true); // Включаем индикацию загрузки
        setErrorGuarantees(null); // Сбрасываем ошибки

        try {
            const data = await getGuarantees(); // Вызов API для получения гарантий
            setGuarantees(data); // Устанавливаем данные гарантий
        } catch (err) {
            setErrorGuarantees(err); // Устанавливаем ошибку, если запрос не удался
        } finally {
            setLoadingGuarantees(false); // Выключаем индикацию загрузки
        }
    };

    // Функция для активации гарантии
    const activateUserGuarantee = async (guaranteeData) => {
        setLoadingActivation(true); // Включаем индикацию загрузки
        setErrorActivation(null); // Сбрасываем ошибки

        try {
            const result = await activateGuarantee(guaranteeData); // Вызов API для активации гарантии
            setActivationResult(result); // Устанавливаем результат активации
        } catch (err) {
            setErrorActivation(err); // Устанавливаем ошибку, если запрос не удался
        } finally {
            setLoadingActivation(false); // Выключаем индикацию загрузки
        }
    };

    useEffect(() => {
        fetchGuarantees(); // Автоматически вызываем запрос при монтировании компонента
    }, []);

    return {
        guarantees,
        loadingGuarantees,
        errorGuarantees,
        fetchGuarantees,
        activateUserGuarantee, // Возвращаем функцию для активации гарантии
        activationResult,
        loadingActivation,
        errorActivation
    };
};
