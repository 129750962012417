import React, {useEffect, useState} from 'react';
import '../assets/css/style.css'
import {useTelegram} from "../TelegramContext";
import {BurgerMenu} from "../assets/svg/icons";
import Menu from "./Menu";
import {Link} from "react-router-dom";

function Header() {

    const { user, triggerVibration } = useTelegram();

    const [selectedLanguage, setSelectedLanguage] = useState('ru');


    return (
        <header style={{top:0}}>
            <div className={'burger-menu'}>

                <Menu/>
            </div>

        </header>
    );
}

export default Header;
