import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../../utils/constants';

import Loader from "../../../components/Loader";
import Header from "../../../components/Header";
import {
    AutoIcon,
    BottomArrowIcon,
    LogoIcon,
    Red2Icon,
    RedInfoIcon,
    RightArrowIcon,
    UserIcon, YellowInfoIcon
} from "../../../assets/svg/icons";
import {useWilStorage} from "../../../hooks/useWillStorage";
import {useSingleData} from "../../../context/SingleContext";
import topImage from "../../../assets/img/main/top2.png";
import FeedbackForm from "../../../components/FeedbackForm";
import WheelStorageInfo from "../include/WheelStorageInfo";

function WheelStorageList() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();

    const {wilStorageAnswer, loadingWilStorage, errorWilStorage, fetchWilStorage} = useWilStorage();
    const {setSelectedSingleData } = useSingleData(); // Достаем функцию для установки выбранного заказа

    const [noData, setNoData] = useState(false);
    const [isOpen,setOpen] = useState(false);
    const [isRed,setIsRed] = useState(false);

    useEffect(()=>{
        if (wilStorageAnswer) {
            console.log(wilStorageAnswer, 'wilStorageAnswer')
            if (wilStorageAnswer?.data.length == 0) {
                setNoData(true)
            } else {

                for (let i = 0; i <  wilStorageAnswer.data.length; i++) {
                    if (isDateInPast(wilStorageAnswer?.data[i]?.dataEnd)) {
                        setIsRed(true);
                    }
                }
                setNoData(false)
            }
        }
    }, [wilStorageAnswer])


    function isDateInPast(dateString) {
        // Функция для перевода месяца с русского на английский
        const convertMonth = (month) => {
            const months = {
                'января': 'January',
                'февраля': 'February',
                'марта': 'March',
                'апреля': 'April',
                'мая': 'May',
                'июня': 'June',
                'июля': 'July',
                'августа': 'August',
                'сентября': 'September',
                'октября': 'October',
                'ноября': 'November',
                'декабря': 'December'
            };
            return months[month];
        };

        // Разделяем строку на день, месяц и год
        const [day, month, year] = dateString.replace(" г.", "").split(" ");
        const englishMonth = convertMonth(month); // Переводим месяц

        // Создаем объект даты
        const givenDate = new Date(`${englishMonth} ${day}, ${year}`);
        const today = new Date(); // Сегодняшняя дата

        console.log(today , givenDate)
        // Сравниваем с сегодняшней датой
        return today > givenDate;
    }


    if (loadingWilStorage) {
        return (
            <Loader/>
        )
    }

    return (

        <div className="wrapper wrapper-flex" style={{padding: 0}}>

            <Header/>

            {noData ?
                <div className="top-block" style={{height: 70}}>
                    <Link to={'/'} className="top-block-logo">
                        <LogoIcon/>
                    </Link>
                </div>
                :

                <div className="top-block" style={{backgroundImage: `url(${topImage})`, borderRadius: 0}}>
                    <Link to={'/'} className="top-block-logo">
                        <LogoIcon/>
                    </Link>
                </div>
            }


            <div
                className={'padding-block'}
                style={{
                    flex: 1,
                    borderRadius: "10px 10px 0 0 ",
                    position: 'relative',
                    top: -10,
                    background: "white",
                    overflow: "scroll"
                }}
            >
                <div className={'top-title-wrapper'} style={{display: 'block'}}>

                    {noData ?
                        <>
                            <div className={'top-title-text'} style={{marginLeft: 0}}>
                                Пока ничего нет на хранении.
                            </div>
                            <div className={'nodata-subtitle'}>
                                Хотите узнать всё о сезонном хранении в Колесо.ру, рассчитать стоимость услуги?
                            </div>

                            <button className={'build-a-route'}>
                                Подробнее о хранении
                            </button>
                        </>

                        :
                        <div className={'top-title-text'} style={{marginLeft: 0, display:"flex"}}>
                            Хранение колёс
                            <div
                                onClick={() => {
                                    setOpen(true)
                                }}
                                style={{flex: 1, display: "flex", justifyContent: "flex-end"}}
                            >
                                {isRed ?
                                    <RedInfoIcon/>
                                    :
                                    <YellowInfoIcon/>
                                }
                            </div>
                        </div>
                    }


                </div>

                <div className={'list-wrapper'}>


                    {noData ?
                        <>
                            <img style={{width: '100%'}} src={require('../../../assets/img/wheel-nodata.png')} alt=""/>
                        </>
                        :
                        <>
                            {wilStorageAnswer?.data && wilStorageAnswer?.data.map((item, index) => {
                                return (
                                    <Link
                                        key={index}
                                        to={'/wheel-storage-single'}
                                        onClick={() => {
                                            setSelectedSingleData(item)
                                            }}
                                            className={`list-item `}
                                            style={{padding: 0}}
                                        >

                                            <div className={'list-item-wrapper'}>
                                                <div className={'list-item-center'}>
                                                    <div style={{display:"flex", justifyContent: "space-between"}}>
                                                        <span style={{color: isDateInPast(item?.dataEnd) ? 'red' : 'black'}}>№ {item?.partyCode}</span>
                                                        {isDateInPast(item?.dataEnd) &&
                                                            <p className={'red-message2'}>
                                                                нужно забрать
                                                                <Red2Icon className={'red-message2-icon'}/>
                                                            </p>
                                                        }
                                                    </div>


                                                </div>
                                                <div className={'list-item-center'}>
                                                    <span style={{
                                                        fontSize: 14,
                                                        fontWeight: '400'
                                                    }}>до {item?.dataEnd}</span>
                                                </div>

                                                <div className={'list-item-bottom'}
                                                     style={{display: 'flex', justifyContent: 'space-between'}}>
                                                    <span>{item?.equipment}, {item?.quantity} шт.</span>
                                                </div>

                                                <BottomArrowIcon className={'bottom-arrow'} style={{transform: 'rotate(-90deg)'}}/>
                                            </div>

                                        </Link>
                                    )
                            })}

                        </>
                    }


                </div>


                {noData &&
                    <FeedbackForm title={'Насколько удобен личный кабинет?'}/>
                }
            </div>


            <div className={'bottom-btns-wrapper'}>
                <button
                    style={{flex: 1, marginRight: 0}}
                    onClick={() => {
                        navigate(-1)
                    }}
                    className={'simple-btn'}
                >
                    Назад
                </button>

            </div>


            <WheelStorageInfo
                isOpen={isOpen}
                setOpen={setOpen}
                isRed={isRed}
            />

        </div>


    );
}

export default WheelStorageList;
